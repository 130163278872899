<template>
  <div class="members main-cnt">
    <div class="left-wp">
      <div class="input-wp">
        <el-input
          v-model.trim="filterText"
          placeholder="输入组织名称搜索"
          :suffix-icon="Search"
        />
      </div>
      <div
        class="tree-wp"
        v-loading="treeLoading"
        element-loading-text="数据加载中"
      >
        <el-scrollbar style="height: 100%">
          <el-tree
            ref="tree"
            :data="treeData"
            :props="{
              children: 'child',
              label: 'org_name',
            }"
            show-checkbox
            check-strictly
            accordion
            node-key="org_id"
            :default-expanded-keys="defaultExpanded"
            :default-checked-keys="defaultChecked"
            :expand-on-click-node="false"
            @node-click="nodeClick"
            @node-expand="nodeExpand"
            @node-collapse="nodeCollapse"
          />
        </el-scrollbar>
      </div>
    </div>
    <div class="right-wp">
      <div class="title">
        {{ titleName.length > 0 ? titleName.join("-") : "选择组织查看明细" }}
      </div>
      <div class="content">
        <common-table
          ref="userTable"
          tableHeight="calc(100vh - 325px)"
          :ischeck="false"
          :ispaging="true"
          :apiName="BasicApi.getUserByOrg"
          :filters="filters"
          :extraParame="{ org_id: currentOrg ? currentOrg.org_id : '' }"
          :columns="tableColumns"
          @statusChange="statusChange"
          @authRange="authRange"
          @openPageRoleRange="openPageRoleRange"
          @reset="passwordReset"
          @edit="memberEdit"
        >
          <template #operate>
            <el-button
              type="primary"
              round
              @click="showOrganizationAdd"
              v-if="authData.indexOf('n_hDbtPj8qdfBAHVuOKZmo09LwEsk6') != -1"
            >
              <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
              新增组织
            </el-button>
            <el-button
              class="black-bg-btn"
              round
              @click="showAddDialog"
              v-if="authData.indexOf('n_DThejKiFl5y17vECtd4pzXOR2NAk') != -1"
            >
              <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
              新增成员
            </el-button>
          </template>
        </common-table>
      </div>
    </div>

    <!-- 新增组织弹框 -->
    <w-dialog
      ref="organization"
      class="organization"
      title="新增组织"
      width="38%"
      btnWidth="140px"
      top="20vh"
      confirmText="确认新增"
      @wConfirm="orgAddConfirm"
    >
      <el-form
        class="org-form"
        ref="orgFormObj"
        :model="orgForm"
        :rules="orgRules"
        labelPosition="top"
      >
        <el-form-item label="组织名称" prop="org_name">
          <el-input
            v-model="orgForm.org_name"
            placeholder="请输入组织名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="上级组织" prop="org_pid">
          <el-cascader
            v-model="orgForm.org_pid"
            :options="treeData"
            :props="{
              checkStrictly: true,
              children: 'child',
              label: 'org_name',
              value: 'org_id',
            }"
            clearable
          />
        </el-form-item>
        <el-form-item label="组织排序" prop="org_sort">
          <el-input
            v-model="orgForm.org_sort"
            type="number"
            placeholder="请输入组织排序"
          ></el-input>
        </el-form-item>
      </el-form>
    </w-dialog>

    <!-- 新增/编辑成员弹框 -->
    <w-dialog
      ref="addDialog"
      class="add-dialog"
      :title="isEdit ? '编辑成员' : '新增成员'"
      width="40%"
      btnWidth="140px"
      top="15vh"
      :confirmText="isEdit ? '确认编辑' : '确认新增'"
      @wConfirm="userAddConfirm"
    >
      <el-form
        class="add-form"
        ref="addForm"
        :model="ruleForm"
        :rules="rules"
        labelPosition="top"
      >
        <el-form-item label="成员姓名" prop="name">
          <el-input
            v-model="ruleForm.name"
            placeholder="请输入成员姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="tel">
          <el-input
            v-model="ruleForm.tel"
            type="number"
            placeholder="请输入手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属部门" prop="department">
          <el-select
            v-model="ruleForm.department"
            clearable
            placeholder="请选择所属部门"
          >
            <el-option
              :label="item.du_name"
              :value="item.du_id"
              v-for="item in postList"
              :key="item.du_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属组织" prop="organization">
          <el-cascader
            v-model="ruleForm.organization"
            :options="treeData"
            :props="{
              checkStrictly: true,
              children: 'child',
              label: 'org_name',
              value: 'org_id',
              emitPath: false,
            }"
            clearable
            collapse-tags
            :show-all-levels="false"
          />
        </el-form-item>

        <el-divider></el-divider>

        <el-form-item class="block" label="选择权限角色" prop="role">
          <el-checkbox-group v-model="ruleForm.role">
            <el-checkbox
              :label="item.nr_id"
              v-for="(item, index) in roleList"
              :key="index"
            >
              {{ item.nr_name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
    </w-dialog>

    <!-- 个人权限 弹框 -->
    <w-dialog
      ref="authDialog"
      class="auth-dialog"
      title="个人权限"
      width="60%"
      btnWidth="140px"
      top="10vh"
      confirmText="提交保存"
      @wConfirm="authConfirm"
    >
      <div class="mcontent" v-loading="authLoading">
        <div class="name">选择权限模块</div>
        <div class="select">
          <el-select
            v-model="cmodule"
            placeholder="请选择权限模块"
            @change="moduleChange"
          >
            <el-option
              :label="item.nm_name"
              :value="item.nm_id"
              v-for="item in authModules"
              :key="item.nm_id"
            ></el-option>
          </el-select>
        </div>
        <div class="checkbox-wp" v-if="authTree[0]">
          <el-checkbox
            v-model="checkAll"
            :indeterminate="isIndeterminate"
            @change="handleCheckAllChange"
          >
            全选/反选</el-checkbox
          >
          <div v-for="item in authTree[0].child" :key="item.n_id">
            <el-checkbox
              v-model="item.checkMenuAll"
              :indeterminate="item.isIndeterminate"
              @change="handleCheckMenuAllChange($event, item)"
              >{{ item.n_name + "（全选）" }}</el-checkbox
            >
            <el-checkbox-group
              v-model="item.checkedAuth"
              @change="handleCheckedAuthChange($event, item)"
            >
              <el-checkbox
                v-for="itm in item.child"
                :key="itm.n_id"
                :label="itm.n_id"
                >{{ itm.n_name }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </w-dialog>

    <!-- 权限范围 弹框 -->
    <RoleRange ref="roleRangeRef" @submit_suc="roleRangeSuc"></RoleRange>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, computed, watch, nextTick } from "vue";
import { Search } from "@element-plus/icons-vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { BasicApi } from "@/plugins/api.js";
import { useStore } from "vuex";
import RoleRange from "@/views/basic/children/RoleRange.vue";

const store = useStore();
const menuTokens = computed(() => store.state.menuToken.menuTokens);
const authData = ref([]);
watch(
  () => menuTokens.value,
  (data) => {
    if (data.length) {
      authData.value = data;
    }
  },
  {
    deep: true,
    immediate: true,
  }
);
/** 搜索框绑定值 */
const filterText = ref("");
/** 树形结构组件对象 */
const tree = ref(null);
/** 树结构加载标识 */
const treeLoading = ref(false);
/** 当前选择的组织 */
const currentOrg = ref(null);
/** 树形结构 数据 */
const treeData = ref([]);
/** 默认展开节点 */
const defaultExpanded = ref([]);
/** 默认选择节点 */
const defaultChecked = ref([]);
/** 节点点击回调 */
const nodeClick = (node) => {
  currentOrg.value = node;
  tree.value.setCheckedNodes([node]);
  titleName.value = [];
  const cnode = tree.value.getNode(node.org_id);
  const parentFind = (data) => {
    if (data.level > 0) {
      titleName.value.unshift(data.data.org_name);
      parentFind(data.parent);
    }
  };
  parentFind(cnode);
  nextTick(() => {
    userTable.value.tableLoad();
  });
};
/** 节点展开事件 */
const nodeExpand = (data) => {
  defaultExpanded.value.push(data.org_id);
};
/** 节点关闭事件 */
const nodeCollapse = (data) => {
  let index = defaultExpanded.value.findIndex((item) => item == data.org_id);
  defaultExpanded.value.splice(index, 1);
};
/** 获取组织树形结构数据 */
const getTreeData = () => {
  treeLoading.value = true;
  BasicApi.getOrgTree({
    keyword: filterText.value,
  }).then((res) => {
    if (res.Code === 200) {
      treeData.value = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取树形组织结构失败！";
      ElMessage.error(msg);
    }
    treeLoading.value = false;
  });
};

/** 当前组织及父级名称 */
const titleName = ref([]);

/** 筛选条件列表 */
const filters = ref([
  {
    name: "keyword",
    filterType: "search",
    value: "",
    placeholder: "搜索人员姓名、账号",
  },
]);
/** 表格对象 */
const userTable = ref(null);
/** 当前操作 表格行数据 */
const currentRow = ref(null);
/** 表格配置数据 */
const tableColumns = ref([
  {
    prop: "u_name",
    label: "姓名",
    minWidth: 100,
    color: "--text-color",
  },
  {
    prop: "uo_uname",
    label: "组织",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    prop: "u_tel",
    label: "账号",
    minWidth: 120,
    color: "--text-third-color",
  },
  {
    prop: "duty",
    objKey: "du_name",
    type: "obj",
    label: "角色",
    minWidth: 120,
    color: "--text-color",
  },
  {
    type: "switch",
    prop: "u_status",
    label: "状态",
    minWidth: 80,
    token: "n_xBuDRO3gEGZe2QHo68brTsAyFKLU",
  },
  {
    type: "operation",
    prop: "",
    label: "操作",
    minWidth: 370,
    bottons: [
      {
        name: "个人权限",
        action: "authRange",
        token: "n_YPZkKmguidRMULpQrhCjHVfIEXx2",
        className: "black-font-btn",
      },
      {
        name: "权限范围",
        action: "openPageRoleRange",
        token: "n_E3KZWk2UG4n9AtQjTrzP6l7V1vph",
        className: "theme-font-btn",
      },
      {
        name: "重置密码",
        action: "reset",
        token: "n_FtAkCNWV74m58PIGToMbweBJjYXu",
        className: "black-font-btn",
      },
      {
        name: "编辑",
        action: "edit",
        token: "n_7pG5lHib1ugdO6UhMmTS329YqFLx",
        className: "theme-font-btn",
      },
    ],
  },
]);
/** 状态改变处理 */
const statusChange = (row) => {
  let data = {
    u_uuid: row.u_uuid,
    u_status: row.u_status == 1 ? 2 : 1,
  };
  BasicApi.setUserStatus(data).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("人员状态修改成功！");
      // 重新获取套餐列表数据
      userTable.value.tableLoad();
    } else {
      let msg = res.Message ? res.Message : "人员状态修改失败！";
      ElMessage.error(msg);
    }
  });
};
/** 表格权限 */
const selectedNode = ref([]);
function getSelectedNodeApi() {
  BasicApi.getSelectedNode({
    uuid: currentRow.value.u_uuid,
  }).then((res) => {
    if (res.Code === 200) {
      selectedNode.value = res.Data && res.Data.node.length ? res.Data.node : [];
    } else {
      let msg = res.Message ? res.Message : "获取个人权限失败！";
      ElMessage.error(msg);
    }
  });
}
const authRange = (row) => {
  currentRow.value = row;
  getSelectedNodeApi();
  authTree.value = [];
  cmodule.value = "";
  authModules.value = [];
  getAuthModule();
  setTimeout(() => {
    authDialog.value.show();
  }, 800);
};
/** 表格重置密码 */
const passwordReset = (row) => {
  ElMessageBox.prompt("请输入新密码", "重置密码", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    inputPattern: /^[\s\S]*.*[^\s][\s\S]*$/,
    inputErrorMessage: "新密码不能为空！",
  })
    .then(({ value }) => {
      const data = {
        u_uuid: row.u_uuid,
        old_pass: row.old_pass,
        u_pass: value,
      };
      BasicApi.resetPassword(data).then((res) => {
        if (res.Code === 200) {
          ElMessage.success("重置密码成功！");
          // 重新获取表格数据
          userTable.value.tableLoad();
        } else {
          let msg = res.Message ? res.Message : "重置密码失败！";
          ElMessage.error(msg);
        }
      });
    })
    .catch(() => {});
};
/** 表格编辑 */
const memberEdit = (row) => {
  currentRow.value = row;
  isEdit.value = true;
  ruleForm.name = row.u_name;
  ruleForm.tel = row.u_tel;
  ruleForm.department = row.u_duid;
  ruleForm.organization = row.uo_oid;
  ruleForm.role = JSON.parse(row.u_role);
  addDialog.value.show();
  getPostData();
  getRoleData();
};

/** 新增组织 弹框 */
const organization = ref(null);
/** 组织 表单对象 */
const orgFormObj = ref(null);
/** 组织 表单数据对象 */
const orgForm = reactive({
  org_name: "", // 组织名称
  org_pid: "", // 上级组织
  org_sort: "", // 组织排序
});
/** 组织 表单规则对象 */
const orgRules = reactive({
  org_name: [
    {
      required: true,
      message: "请输入组织名称",
      trigger: "blur",
    },
  ],
  org_pid: [
    {
      required: true,
      message: "请选择上级组织",
      trigger: "change",
    },
  ],
  org_sort: [
    {
      required: true,
      message: "请输入组织排序",
      trigger: "blur",
    },
  ],
});
/** 弹出 新增组织 弹框 */
const showOrganizationAdd = () => {
  // 表单验证重置
  if (orgFormObj.value) {
    orgFormObj.value.resetFields();
  }
  orgForm.org_name = "";
  orgForm.org_pid = "";
  orgForm.org_sort = "";
  organization.value.show();
};
/** 新增组织 确认 */
const orgAddConfirm = () => {
  orgFormObj.value.validate((valid) => {
    if (valid) {
      organization.value.isLoading = true;
      let data = {
        org_name: orgForm.org_name,
        org_pid: orgForm.org_pid[orgForm.org_pid.length - 1],
        org_sort: orgForm.org_sort,
      };
      BasicApi.orgAdd(data).then((res) => {
        organization.value.isLoading = false;
        if (res.Code === 200) {
          ElMessage.success("新增组织成功！");
          organization.value.close();
          defaultChecked.value = tree.value.getCheckedKeys();
          // 重新获取一下组织数据
          getTreeData();
        } else {
          let msg = res.Message ? res.Message : "新增组织失败！";
          ElMessage.error(msg);
        }
      });
    }
  });
};

/** 新增/编辑 成员弹框 */
const addDialog = ref(null);
/** 当前是否是编辑操作 */
const isEdit = ref(false);
/** 表单对象 */
const addForm = ref(null);
/** 表单数据对象 */
const ruleForm = reactive({
  name: "", // 成员姓名
  tel: "", // 手机号码
  department: "", // 所属部门
  organization: [], // 所属组织
  role: [], // 权限角色
});
/** 表单规则对象 */
const rules = reactive({
  name: [
    {
      required: true,
      message: "请输入成员姓名",
      trigger: "blur",
    },
  ],
  tel: [
    {
      required: true,
      message: "请输入手机号码",
      trigger: "blur",
    },
  ],
  department: [
    {
      required: true,
      message: "请选择所属部门",
      trigger: "change",
    },
  ],
  organization: [
    {
      required: true,
      message: "请选择所属组织",
      trigger: "change",
    },
  ],
  role: [
    {
      required: true,
      message: "请选择权限角色",
      trigger: "change",
    },
  ],
});
/** 岗位列表数据 */
const postList = ref([]);
/** 角色列表数据 */
const roleList = ref([]);
/** 弹出 新增/编辑成员 弹框 */
const showAddDialog = () => {
  // 表单验证重置
  if (addForm.value) {
    addForm.value.resetFields();
  }
  ruleForm.name = "";
  ruleForm.tel = "";
  ruleForm.department = "";
  ruleForm.organization = [];
  ruleForm.role = [];
  isEdit.value = false;
  addDialog.value.show();
  getPostData();
  getRoleData();
};
/** 获取岗位数据 */
const getPostData = () => {
  BasicApi.getDuty().then((res) => {
    if (res.Code === 200) {
      postList.value = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取部门数据失败！";
      ElMessage.error(msg);
    }
  });
};
/** 获取角色数据 */
const getRoleData = () => {
  BasicApi.getRole().then((res) => {
    if (res.Code === 200) {
      roleList.value = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取角色数据失败！";
      ElMessage.error(msg);
    }
  });
};
/** 确认添加成员 */
const userAddConfirm = () => {
  addForm.value.validate((valid) => {
    if (valid) {
      addDialog.value.isLoading = true;
      let data = {
        u_name: ruleForm.name,
        u_tel: ruleForm.tel,
        org_id: ruleForm.organization,
        u_role: ruleForm.role.length ? ruleForm.role.join(",") : "",
        u_duid: ruleForm.department,
      };
      let url = "userAdd";
      if (isEdit.value) {
        data.u_uuid = currentRow.value.u_uuid;
        url = "userUpdate";
      }
      BasicApi[url](data).then((res) => {
        addDialog.value.isLoading = false;
        let text = isEdit.value ? "编辑成员" : "新增成员";
        if (res.Code === 200) {
          ElMessage.success(`${text}成功！`);
          addDialog.value.close();
          if (isEdit.value) {
            // 重新获取表格数据
            userTable.value.tableLoad();
          }
        } else {
          let msg = res.Message ? res.Message : `${text}失败！`;
          ElMessage.error(msg);
        }
      });
    }
  });
};

/** 个人权限弹框 */
const authDialog = ref(null);
/** 权限模块列表 */
const authModules = ref([]);
/** 选择的权限模块 */
const cmodule = ref("");
/** 是否正在加载权限数据 */
const authLoading = ref(false);
/** 权限列表 */
const authTree = ref([]);
/** 获取权限模块 */
const getAuthModule = () => {
  BasicApi.getModule().then((res) => {
    if (res.Code === 200) {
      authModules.value = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取权限模板失败！";
      ElMessage.error(msg);
    }
  });
};
/** 获取模块下权限 */
const getNodes = () => {
  authLoading.value = true;
  BasicApi.getNode({
    nm_id: cmodule.value,
  }).then((res) => {
    authLoading.value = false;
    if (res.Code === 200) {
      const nodeArr = JSON.parse(JSON.stringify(selectedNode.value));
      if (res.Data[0] && res.Data[0].child && res.Data[0].child.length > 0) {
        if (nodeArr && nodeArr.length > 0) {
          let checkedArr = [];
          res.Data[0].child.forEach((item) => {
            if (item.child && item.child.length > 0) {
              let flag = item.child.every((itm) => nodeArr.includes(itm.n_id));
              let flag2 = item.child.every(
                (itm) => !nodeArr.includes(itm.n_id)
              );
              if (flag) {
                item.isIndeterminate = false;
                item.checkMenuAll = true;
                item.checkedAuth = item.child.map((itm) => itm.n_id);
                checkedArr.push(item.n_id);
              } else if (flag2) {
                item.isIndeterminate = false;
                item.checkMenuAll = false;
                item.checkedAuth = [];
              } else {
                item.isIndeterminate = true;
                item.checkMenuAll = false;
                let arr = [];
                item.child.forEach((itm) => {
                  if (nodeArr.includes(itm.n_id)) {
                    arr.push(itm.n_id);
                  }
                });
                item.checkedAuth = arr;
              }
            } else {
              item.isIndeterminate = false;
              item.checkMenuAll = false;
              item.checkedAuth = [];
            }
          });
          if (
            checkedArr.length > 0 &&
            checkedArr.length === res.Data[0].child.length
          ) {
            checkAll.value = true;
            isIndeterminate.value = false;
          } else if (res.Data[0].child.every((itm) => !itm.isIndeterminate)) {
            checkAll.value = false;
            isIndeterminate.value = false;
          } else {
            checkAll.value = false;
            isIndeterminate.value = true;
          }
        } else {
          checkAll.value = false;
          isIndeterminate.value = false;
          res.Data[0].child.forEach((item) => {
            item.isIndeterminate = false;
            item.checkMenuAll = false;
            item.checkedAuth = [];
          });
        }
      } else {
        checkAll.value = false;
        isIndeterminate.value = false;
      }
      authTree.value = res.Data ? res.Data : [];
    } else {
      let msg = res.Message ? res.Message : "获取权限数据失败！";
      ElMessage.error(msg);
    }
  });
};
/** 模块切换处理 */
const moduleChange = () => {
  checkAll.value = false;
  isIndeterminate.value = false;
  getNodes();
};

/** 模块是否全选 */
const checkAll = ref(false);
/** 全选框勾选状态 */
const isIndeterminate = ref(false);
/** 模块全选状态改变处理 */
const handleCheckAllChange = (val) => {
  if (val) {
    authTree.value[0].child.forEach((item) => {
      item.checkMenuAll = true;
      item.isIndeterminate = false;
      if (item.child && item.child.length > 0) {
        item.checkedAuth = item.child.map((itm) => itm.n_id);
      } else {
        item.checkedAuth = [];
      }
    });
  } else {
    authTree.value[0].child.forEach((item) => {
      item.checkMenuAll = false;
      item.isIndeterminate = false;
      item.checkedAuth = [];
    });
  }
  isIndeterminate.value = false;
};
/** 菜单全选状态改变 */
const handleCheckMenuAllChange = (val, obj) => {
  obj.checkedAuth =
    val && obj.child && obj.child.length > 0
      ? obj.child.map((item) => item.n_id)
      : [];
  obj.isIndeterminate = false;
  if (val) {
    let flag = authTree.value[0].child.every((itm) => itm.checkMenuAll);
    if (flag) {
      checkAll.value = true;
    } else {
      checkAll.value = false;
    }
  } else {
    checkAll.value = false;
  }
  let arr = [];
  authTree.value[0].child.forEach((item) => {
    if (item.checkMenuAll) {
      arr.push(item);
    }
  });
  isIndeterminate.value =
    arr.length > 0 && arr.length < authTree.value[0].child.length;
};
/** 权限选择改变处理 */
const handleCheckedAuthChange = (value, obj) => {
  const checkedCount = value.length;
  obj.checkMenuAll = checkedCount === obj.child.length;
  obj.isIndeterminate = checkedCount > 0 && checkedCount < obj.child.length;
  if (obj.checkMenuAll) {
    let flag = authTree.value[0].child.every((itm) => itm.checkMenuAll);
    if (flag) {
      checkAll.value = true;
    } else {
      checkAll.value = false;
    }
  } else {
    checkAll.value = false;
  }
  let arr = [];
  authTree.value[0].child.forEach((item) => {
    if (item.checkMenuAll) {
      arr.push(item);
    }
  });
  isIndeterminate.value =
    arr.length > 0 && arr.length < authTree.value[0].child.length;
};
/** 权限修改确认 */
const authConfirm = () => {
  authDialog.value.isLoading = true;
  if (!cmodule.value || !authTree.value[0]) {
    ElMessage.warning("请选择权限模块！");
    authDialog.value.isLoading = false;
    return;
  }

  let arr = [];
  authTree.value[0].child.forEach((item) => {
    arr = arr.concat(item.checkedAuth);
  });
  let data = {
    nm_id: cmodule.value,
    u_uuid: currentRow.value.u_uuid,
    node: arr.join(","),
  };
  BasicApi.updateModule(data).then((res) => {
    authDialog.value.isLoading = false;
    if (res.Code === 200) {
      ElMessage.success("权限修改成功！");
      getSelectedNodeApi();
    } else {
      let msg = res.Message ? res.Message : "权限修改失败！";
      ElMessage.error(msg);
    }
  });
};

/** 变更成员权限范围 **/
// 成员权限范围弹框挂载
const roleRangeRef = ref(null);
// 打开权限范围弹出框
const openPageRoleRange = (row) => {
  roleRangeRef.value.openPage(row);
};
// 编辑权限范围回显
const roleRangeSuc = () => {
  userTable.value.tableLoad();
};
/** 变更成员权限范围 **/
/**
 * 生命周期
 */
onMounted(() => {
  getTreeData();
});
</script>

<style lang="scss">
.el-cascader {
  width: 100%;
}

.members {
  font-family: "Source Han Sans CN";
  display: flex;
  background-color: var(--theme-bg-color);

  .left-wp {
    flex: 1;
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--bg-content-color);
    padding: 20px 0;
    box-sizing: border-box;

    .input-wp {
      padding: 0 15px;
    }

    > .tree-wp {
      margin-top: 15px;
      height: calc(100% - 58px);

      .el-tree {
        background-color: var(--theme-bg-color);
        padding: 0 15px;

        .el-tree-node {
          .el-tree-node__content {
            height: 40px;

            > label.el-checkbox {
              display: none;
            }

            .el-tree-node__expand-icon {
              font-size: 16px;
            }
          }

          .el-tree-node__content:hover {
            background-color: var(--search-bg-color);
          }
        }

        .el-tree-node:focus {
          > .el-tree-node__content {
            background-color: var(--search-bg-color);
          }
        }

        .el-tree-node.is-checked {
          > .el-tree-node__content {
            background-color: var(--search-bg-color);

            > .el-tree-node__label {
              color: var(--theme-color);
            }

            > .el-tree-node__expand-icon {
              color: var(--theme-color);
            }

            > .el-tree-node__expand-icon.is-leaf {
              color: transparent;
            }
          }
        }
      }
    }
  }

  .right-wp {
    flex: 3;
    margin-left: 16px;
    border-radius: 10px;
    background-color: var(--bg-color);

    .content {
      padding: 20px;
    }
  }

  .organization {
    .el-dialog {
      min-width: 640px;

      .el-dialog__body {
        padding: 0 15px 50px;

        .org-form {
          display: flex;
          flex-wrap: wrap;

          .el-form-item {
            width: 50%;
            padding: 20px 15px 0;
            margin-bottom: 0;

            .el-form-item__content {
              .el-select {
                width: 100%;

                .el-input {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  .add-dialog {
    .el-dialog {
      min-width: 840px;

      .el-dialog__body {
        padding: 0 15px 40px;

        .add-form {
          display: flex;
          flex-wrap: wrap;

          .el-form-item {
            width: 50%;
            padding: 20px 15px 0;
            margin-bottom: 0;

            .el-form-item__content {
              .el-select {
                width: 100%;

                .el-input {
                  width: 100%;
                }
              }
            }
          }

          .el-form-item.block {
            width: 100%;
          }

          .el-divider {
            margin: 30px 0 0 15px;
            width: calc(100% - 30px);
          }
        }
      }
    }
  }

  .auth-dialog {
    .el-dialog {
      min-width: 840px;

      .el-dialog__body {
        padding: 0;

        .mcontent {
          padding: 25px 25px 50px;
          min-height: 600px;

          .name {
            font-size: 15px;
            color: var(--top-text-color);
            margin-bottom: 15px;
          }

          .select {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}
</style>
