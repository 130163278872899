<template>
  <div id="mermbers_role_range">
    <w-dialog
      ref="pageDialog"
      class="auth-dialog"
      :title="pageConf.title"
      width="60%"
      btnWidth="140px"
      top="10vh"
      confirmText="提交保存"
      @wConfirm="onSubmit"
    >
      <div class="mcontent">
        <div class="name">选择权限范围类型</div>
        <div class="select">
          <el-select v-model="pageConf.type" placeholder="请选择权限类型">
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in pageConf.rangeTypeList"
              :key="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="checkbox-wp" v-if="pageConf.rangeList.length > 0">
          <el-checkbox v-model="pageConf.isAllChose" @change="roleRangeAllChose"
            >全选/反选</el-checkbox
          >
          <el-checkbox-group v-model="pageConf.val">
            <el-checkbox
              :key="item.id"
              :label="item.id"
              v-for="item in pageConf.rangeList"
              >{{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
    </w-dialog>
  </div>
</template>

<script>
import { defineComponent, watch, ref, reactive } from "vue";
import { BasicApi } from "@/plugins/api.js";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "mermbers_role_range",
  setup(props, { emit }) {
    // 打开弹框
    const selectedNode = ref([]);
    function getSelectedNodeApi() {
      BasicApi.getSelectedNode({
        uuid: pageConf.userInfo.u_uuid,
      }).then((res) => {
        if (res.Code === 200) {
          selectedNode.value = res.Data;
        } else {
          let msg = res.Message ? res.Message : "获取个人权限失败！";
          ElMessage.error(msg);
        }
      });
    }
    const openPage = (userinfo) => {
      pageConf.userInfo = userinfo;
      getSelectedNodeApi();
      pageConf.type = "";
      setTimeout(() => {
        pageDialog.value.show();
      }, 800);
    };

    // 权限范围弹框挂载
    const pageDialog = ref(null);

    // 权限范围配置参数
    const pageConf = reactive({
      title: "权限范围",
      userInfo: {},
      type: "", // 选择的权限范围类型
      val: [],
      isAllChose: false,
      rangeTypeList: [
        // {
        //   //权限范围类型列表
        //   id: 1,
        //   name: "项目范围",
        // },
        // {
        //   id: 2,
        //   name: "酒店范围",
        // },
        // {
        //   id: 3,
        //   name: "餐厅范围",
        // },
        {
          id: 4,
          name: "门店范围",
        },
		{
		  id: 5,
		  name: "园区范围",
		}
      ],
      rangeList: [], // 权限范围列表
    });

    // 监听权限范围类型改变
    watch(
      () => pageConf.type,
      () => {
        // 清除权限范围列表和选中的值
        pageConf.rangeList = [];
        pageConf.val = [];
        pageConf.isAllChose = false;

        // 打开按钮加载状态
        pageDialog.value.isLoading = true;

        if (parseInt(pageConf.type) === 1) {
          // 项目范围
          BasicApi.projectSelect().then((res) => {
            if (res.Code === 200) {
              // 权限范围列表赋值
              res.Data.forEach((v) => {
                pageConf.rangeList.push({
                  id: v.p_id,
                  name: v.p_name,
                });
              });

              // 存放已存在的值
              if (
                typeof selectedNode.value.u_project_range != "undefined" &&
                selectedNode.value.u_project_range != null
              ) {
                pageConf.val =
                  typeof selectedNode.value.u_project_range === "string"
                    ? JSON.parse(selectedNode.value.u_project_range)
                    : selectedNode.value.u_project_range;
              }
            } else {
              ElMessage.error(res.Message);
            }

            // 关闭按钮加载状态
            pageDialog.value.isLoading = false;
          });
        } else if (parseInt(pageConf.type) === 2) {
          // 酒店范围
          BasicApi.getAllHotelData().then((res) => {
            if (res.Code === 200) {
              // 权限范围列表赋值
              res.Data.forEach((v) => {
                pageConf.rangeList.push({
                  id: v.h_id,
                  name: v.h_name,
                });
              });

              // 存放已存在的值
              if (
                typeof selectedNode.value.u_hotel_range != "undefined" &&
                selectedNode.value.u_hotel_range != null
              ) {
                pageConf.val =
                  typeof selectedNode.value.u_hotel_range === "string"
                    ? JSON.parse(selectedNode.value.u_hotel_range)
                    : selectedNode.value.u_hotel_range;
              }
            } else {
              ElMessage.error(res.Message);
            }

            // 关闭按钮加载状态
            pageDialog.value.isLoading = false;
          });
        } else if (parseInt(pageConf.type) === 3) {
          // 餐厅范围
          BasicApi.getAllDinnerData().then((res) => {
            if (res.Code === 200) {
              // 权限范围列表赋值
              res.Data.forEach((v) => {
                pageConf.rangeList.push({
                  id: v.dr_id,
                  name: v.dr_name,
                });
              });

              // 存放已存在的值
              if (
                typeof selectedNode.value.u_dinner_range != "undefined" &&
                selectedNode.value.u_dinner_range != null
              ) {
                pageConf.val =
                  typeof selectedNode.value.u_dinner_range === "string"
                    ? JSON.parse(selectedNode.value.u_dinner_range)
                    : selectedNode.value.u_dinner_range;
              }
            } else {
              ElMessage.error(res.Message);
            }

            // 关闭按钮加载状态
            pageDialog.value.isLoading = false;
          });
        } else if (parseInt(pageConf.type) === 4) {
          // 门店范围
          BasicApi.getAllPolyMerchantData().then((res) => {
            if (res.Code === 200) {
                // 权限范围列表赋值
                if(res.Data) {
                  res.Data.forEach((v) => {
                  pageConf.rangeList.push({
                    id: v.pom_id,
                    name: v.pom_name,
                  });
                });
              }

              // 存放已存在的值
              if (
                typeof selectedNode.value.u_polymerization_range !=
                  "undefined" &&
                selectedNode.value.u_polymerization_range != null
              ) {
                pageConf.val =
                  typeof selectedNode.value.u_polymerization_range === "string"
                    ? JSON.parse(selectedNode.value.u_polymerization_range)
                    : selectedNode.value.u_polymerization_range;
              }
            } else {
              ElMessage.error(res.Message);
            }

            // 关闭按钮加载状态
            pageDialog.value.isLoading = false;
          });
        }
		else if (parseInt(pageConf.type) === 5) {
			
		  // 园区范围
		  BasicApi.getScenicByProject({}).then((res) => {
		    if (res.Code == 200) {
		      // projectSelect.value = res.Data;
			  if (res.Code === 200) {
			      // 权限范围列表赋值
			      if(res.Data) {
			        res.Data.forEach((v) => {
			        pageConf.rangeList.push({
			          id: v.s_id,
			          name: v.s_name,
			        });
			      });
			    }
			  		
			    // 存放已存在的值
			    if (
			      typeof selectedNode.value.u_scenic_range !=
			        "undefined" &&
			      selectedNode.value.u_scenic_range != null
			    ) {
			      pageConf.val =
			        typeof selectedNode.value.u_scenic_range === "string"
			          ? JSON.parse(selectedNode.value.u_scenic_range)
			          : selectedNode.value.u_scenic_range;
			    }
			  } else {
			    ElMessage.error(res.Message);
			  }
			  		
			  // 关闭按钮加载状态
			  pageDialog.value.isLoading = false;
		    }
		  });
		  
		}
      }
    );

    // 全选/反选点击事件
    const roleRangeAllChose = () => {
      pageConf.val = [];
      if (pageConf.isAllChose === true) {
        pageConf.rangeList.forEach((v) => {
          pageConf.val.push(v.id);
        });
      }
    };

    // 监听已选中的权限范围值改变
    watch(
      () => pageConf.val,
      () => {
        roleRangeChose();
      }
    );

    // 根据选择的权限范围是否到达全选标准
    const roleRangeChose = () => {
      var isAllChose = true;
      if (pageConf.rangeList.length > 0) {
        pageConf.rangeList.forEach((v) => {
          if (pageConf.val.indexOf(v.id) === -1) {
            isAllChose = false;
            return "";
          }
        });
      } else {
        isAllChose = false;
      }

      pageConf.isAllChose = isAllChose;
    };

    // 提交保存
    const onSubmit = () => {
      // 权限范围类型判断
	
      if (pageConf.type === "") {
        ElMessage.error("请选择权限范围类型");
        return false;
      }

      // 打开按钮加载状态
      pageDialog.value.isLoading = true;

      // 执行提交操作
	 let data = {
	   u_uuid: pageConf.userInfo.u_uuid,
	   role_range_type: pageConf.type,
	   role_range_value: pageConf.val,
	 }
	
      BasicApi.roleUpdateRoleRange(data).then((res) => {
        if (res.Code === 200) {
          ElMessage.success(res.Message);
          // pageDialog.value.close()
          getSelectedNodeApi();
          emit("submit_suc");
        } else {
          ElMessage.error(res.Message);
        }

        // 关闭按钮加载状态
        pageDialog.value.isLoading = false;
      });
    };

    return {
      pageConf,
      pageDialog,
      openPage,
      roleRangeAllChose,
      onSubmit,
    };
  },
});
</script>

<style>
</style>
